import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theming/Theming';

export const pagePaddingCss = `
  padding: 0 ${theme.general.pagePadding.sm};
  ${theme.only.md} {
    padding: 0 ${theme.general.pagePadding.md};
  }
  ${theme.above.lg} {
    padding: 0 ${theme.general.pagePadding.lg};
  }
`;

export const MaxWidthWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: ${theme.general.maxWidth};
  ${pagePaddingCss}
`;

export const Inner = styled('div')`
  position: relative;
`;

export const PagePaddingWrapper = styled('div')`
  ${pagePaddingCss}
`;

export const PagePadding = ({ children }) => {
  return (
    <PagePaddingWrapper className="page-padding">{children}</PagePaddingWrapper>
  );
};

const MaxWidth = ({ className = '', children }) => {
  return (
    <MaxWidthWrapper className={cx(className, 'max-width-wrapper')}>
      {children}
    </MaxWidthWrapper>
  );
};

export default MaxWidth;
